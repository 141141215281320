<template>

  <div class="collapse menu-collapse" id="collapseCountries" data-parent="#accordion">

    <div class="menu-accordion p-2">

      <div class="row">
        <div class="col-6">
          <div @click="setCountry('')">
            <div class="item-wrapper">
              <img v-bind:src="getCountryFlag('')" width="13" height="13">
              <span class="ml-2 item-wrapper-span-active">All Countries </span>
            </div>
          </div>
        </div>
        <div class="col-6" v-for="(c,index) in countries" v-bind:key="getCountryKey(c,index)">
          <div @click="setCountry(c)">
            <div class="item-wrapper">
              <img v-bind:src="getCountryFlag(c.country_code)" width="13" height="13">
              <span class="ml-2 item-wrapper-span-active">{{ c.country_name }} ({{ c.games }}) </span>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>

import axios from "../../services/api";

export default {
  name: "countries",
  data: function () {
    return {
      countries: [],
    }
  },
  mounted() {
    this.getCountries();

  },
  computed: {
    sports: function () {
      return this.$store.state.sports
    },
    betslip_count: function () {

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    market_id: function () {
      return this.$store.state.market_id;
    },
    hour: function () {

      return -1;
      // this.$store.state.hour;
    },
    date: function () {

      return this.$store.state.upcoming_date

    }
  },
  methods: {

    getMarketClass: function (menu) {

      return menu === this.menu ? 'market-list-active' : 'market-list';

    },

    getKey: function (index) {

      return Math.random().toString(20).replace('0.', 'random-id-' + index + '-');

    },

    setHour: function (h) {

      this.selected_hour = h;
      this.$store.dispatch("setHour", h);

    },
    humanHourName: function (h) {

      if (h === -1) {

        return 'All Time'
      }

      return h === '' || h === "" || h === 0 ? 'Time' : h + ' Hours'

    },

    getCountries: function () {

      var vm = this;
      var sport_id = this.$store.state.sport_id;
      var path = process.env.VUE_APP_URL_MENU.replace("{sport_id}", sport_id);

      vm.busy = true;
      axios.post(path, JSON.stringify({
        hours: vm.hour,
        date: vm.date,
      }))
          .then(res => {

            vm.busy = false;

            vm.countries = res.data.message.country;

          })
          .catch(error => {

            console.log(error)

          })
    },
    isLoading: function (index) {

      const i = this.loading.indexOf(index);
      if (i > -1) {
        return true;

      }

      return false;
    },
    selectedClass: function (index) {

      const i = this.visible.indexOf(index);
      if (i > -1) {
        return 'selected';
      }

      return '';
    },
    isVisisble: function (index) {

      const i = this.visible.indexOf(index);
      if (i > -1) {

        return true;

      }
      return false;

    },
    loadData: function (index) {

      const i = this.loading.indexOf(index);

      if (i > -1) {  // index already exisits in loading array

        // toggle visibility
        const i = this.visible.indexOf(index);

        // check if its visible
        if (i > -1) {

          this.visible.splice(i, 1); // hide visibility

        } else {

          this.visible.push(index); // else make visible

        }

      } else {

        this.loading.push(index);
        // make visible
        this.visible.push(index);
      }

    },
    getCountryKey: function (league, index) {

      var prefix = league === undefined || league.sport_id == undefined ? index : league.sport_id;
      return Math.random().toString(10).replace('0.', 'sport-id-' + prefix + '-');

    },
    setCountry: function (country) {

      document.getElementById("countries-btn").click();
      this.menu = 'country';

      if(country == '') {

        this.$store.dispatch("setCountryCode", "");

      } else {

        this.$store.dispatch("setCountryCode", country.country_code);

      }
      document.getElementById('dp').classList.add('stick');
    },
    geChildPageActiveClass: function (childpage) {

      return childpage === this.$store.state.upcoming_date ? 'selected-date' : "";

    },
    setUpcomingDate: function (date,index,label) {

      this.menu = date;
      this.$store.dispatch("setUpcomingDate", date)
      this.selectedOption = this.getDayName(index, label)+'s Matches';

    },
    setUpcomingAll: function () {

      this.menu = 'all';
      this.$store.dispatch("setUpcomingDate", '');
      this.selectedOption = 'Upcoming Matches';
    },
    getDayName: function (i, label) {

      console.log('index ==> ' + i + ' label ==> ' + label);

      if (i === 0) {

        return 'Today';
      }

      if (i === 1) {

        return 'Tomorrow';

      }

      return label;

    },
  },
}

</script>

<style scoped>

</style>